<template>
	<div>
		<base-data-table
			title="Admins"
			:headers="headers"
			:provider="$admins"
			:fetch-function="getAdmins"
			:actions="actions"
			@view="onViewAdmin"
			@click:add="onAddAdmin"
			@delete="onConfirmDeleteAdmin"
			@update="onOpenUpdateModal"
			@status="onChangeStatus"
		>
			<template v-slot:[`item.name`]="{ item }">
				{{ item.vFirstName }} {{ item.vLastName }}
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<v-chip
					small
					v-if="item.eStatus"
					:color="
						{
							Pending: 'orange',
							Active: 'primary',
							Inactive: 'error',
						}[item.eStatus]
					"
				>
					{{ item.eStatus }}
				</v-chip>
			</template>
		</base-data-table>
		<v-dialog
			max-width="950"
			v-model="adminForm.dialog"
			content-class="tw-rounded-2xl tw-shadow-lg"
			persistent
		>
			<v-card class="tw-pt-4">
				<AdminForm
					:adminData="adminForm.data"
					:onView="onView"
					:onUpdate="onUpdate"
					@updateAdmin="handleUpdateAdmin"
					@createAdmin="handleCreateAdmin"
					@closeModal="onCloseModal"
				>
				</AdminForm>
			</v-card>
		</v-dialog>
		<v-dialog
			content-class="tw-rounded-3xl tw-bg-white"
			persistent
			max-width="676"
			v-model="confirmDialog.show"
		>
			<v-card elevation="0" class="tw-p-0 tw-mx-0">
				<div class="tw-text-center tw-py-5 tw-px-6 lg:tw-py-10 lg:tw-px-9">
					<h1 class="tw-text-2xl tw-font-semibold">Delete Admin</h1>
					<div
						class="tw-mt-6 tw-mb-9 xl:tw-mt-8 xl:tw-mb-14 tw-text-center"
					>
						Are you sure about deleting this Categorty because once you
						continue this action can't be undone.
					</div>
					<v-card-actions class="tw-flex tw-space-x-4">
						<v-btn
							depressed
							color="accent"
							outlined
							@click="confirmDialog.show = false"
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							><span class="tw-capitalize tw-text-lg tw-font-medium"
								>Cancel</span
							></v-btn
						>
						<v-btn
							depressed
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							@click="handleDeleteAdmin"
							dark
						>
							<span class="tw-capitalize tw-text-lg tw-font-medium"
								>Delete Admin</span
							></v-btn
						>
					</v-card-actions>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import AdminForm from '@/components/Forms/AdminForm'
import BaseDataTable from '@/components/base/BaseDataTable'
import { mapActions, mapGetters } from 'vuex'
import { toFormData, omitEmpties } from 'vuelpers'

export default {
	name: 'admin',
	metaInfo: {
		title: 'Admins | zx55',
	},
	components: {
		AdminForm,
		BaseDataTable,
	},
	data() {
		return {
			property: 'value',
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Email', value: 'email' },
				{ text: 'Ip Address', value: 'vIPAddress' },
				{ text: 'Role', value: 'vRole' },

				{ text: 'Status', align: 'center', value: 'status' },
				{ text: 'Actions', value: 'actions' },
			],
			actions: [
				{
					icon: 'mdi-eye',
					text: 'view',
					event: 'view',
				},
				{
					icon: 'mdi-pencil',
					text: 'Edit',
					event: 'update',
				},
				{
					// icon: 'mdi-check',
					event: 'status',
					text: (item) => {
						return {
							Pending: 'Activate',
							Active: 'Deactivate',
							Inactive: 'Activate',
						}[item.eStatus]
					},
				},
				{
					icon: 'mdi-delete',
					text: 'delete',
					event: 'delete',
				},
			],
			adminForm: {
				dialog: false,
				data: {},
			},
			onView: false,
			onUpdate: false,
			confirmDialog: {
				id: null,
				show: false,
			},
		}
	},
	methods: {
		...mapActions('admin', [
			'getAdmins',
			'getAdminById',
			'createAdmin',
			'updateAdmin',
			'deleteAdminById',
		]),

		onViewAdmin(item) {
			this.adminForm.dialog = true
			this.adminForm.data = item
			this.onView = true
		},

		onOpenUpdateModal(item) {
			// this.adminForm.data = item
			// this.onUpdate = true
			// this.adminForm.dialog = true
			this.adminForm.dialog = true
			this.adminForm.data = item
			this.onUpdate = true
		},
		onAddAdmin() {
			this.adminForm.dialog = true
		},
		onChangeStatus(item) {
			this.handleUpdateAdmin({
				iUserId: item.iUserId,
				eStatus: {
					Pending: 'Active',
					Active: 'Inactive',
					Inactive: 'Active',
				}[item.eStatus],
			})
		},
		async handleUpdateAdmin(item) {
			let excludeData = omitEmpties(item)
			let data = toFormData(excludeData, { _method: 'PATCH' })
			let [err] = await this.updateAdmin(data)
			if (!err) {
				this.$notify({
					group: 'foo',
					title: 'Success',
					type: 'success',
					text: 'Admin updated Successfully',
				})
				this.adminForm.dialog = false
			} else {
				this.$notify({
					group: 'foo',
					title: 'Error',
					type: 'error',
					text: 'An error occured while updating admin',
				})
			}
		},
		async onConfirmDeleteAdmin(item) {
			this.confirmDialog.show = true
			this.confirmDialog.id = item.iUserId
		},
		async handleDeleteAdmin() {
			let [err] = await this.deleteAdminById(this.confirmDialog.id)
			if (!err) {
				this.$notify({
					group: 'foo',
					title: 'Success',
					type: 'success',
					text: 'Admin Deleted Successfully',
				})
				this.confirmDialog.show = false
			} else {
				this.$notify({
					group: 'foo',
					title: 'Error',
					type: 'error',
					text: 'An error occured while deleting Admin',
				})
			}
		},
		async handleCreateAdmin(item) {
			let data = toFormData(omitEmpties(item))
			let [err] = await this.createAdmin(data)
			if (!err) {
				this.$notify({
					group: 'foo',
					title: 'Success',
					type: 'success',
					text: 'Admin Created Successfully',
				})
				this.adminForm.dialog = false
			} else {
				this.$notify({
					group: 'foo',
					title: 'Error',
					type: 'error',
					text: 'An error occured while creating Admin',
				})
			}
			// await this.createAdmin(item)
		},
		// getFilters(item) {
		// 	return item
		// 		? Object.entries(item)
		// 				.filter(([key]) => key.startsWith('vFilter'))
		// 				.map(([key, value]) => {
		// 					return {
		// 						value
		// 						id: +key.replace('vFilter', ''),
		// 					}
		// 				})
		// 		: []
		// },
		onCloseModal() {
			this.onView = false
			this.onUpdate = false
			this.adminForm = {
				dialog: false,
				data: {},
			}
			setTimeout(() => {
				this.onView = false
				this.onUpdate = false
			}, 1000)
		},
	},
	computed: {
		...mapGetters('admin', ['$admins']),
	},

	async created() {
		// await this.getAdmins()
	},
}
</script>

<style></style>
