





















































































































































































































































import Vue from 'vue'

import { mapGetters } from 'vuex'
import { openFilePicker } from 'vuelpers'
import { Accessors } from 'vue/types/options'
import { getValidators } from '@/mixins/FormMixin'
import { User, VFormRef, BackendError, Role, Attachment } from '@/types'

const initialType = (v?: User): Partial<User> => ({
	iUserId: v?.iUserId,
	vFirstName: v?.vFirstName,
	vLastName: v?.vLastName,
	email: v?.email,
	eStatus: v?.eStatus,
	vMobileNumber: v?.vMobileNumber,
	vProfileImage: v?.vProfileImage,
	vIPAddress: v?.vIPAddress,
	vRole: v?.vRole || Role.Admin,
})

export default Vue.extend({
	name: 'adminForm',
	props: {
		adminData: Object as Vue.PropType<User>,
		onView: {
			type: Boolean as Vue.PropType<boolean>,
			default: false,
		},
		onUpdate: {
			type: Boolean as Vue.PropType<boolean>,
			default: false,
		},
	},
	watch: {
		adminData: {
			deep: true,
			immediate: true,
			handler(v: User) {
				if (this.onUpdate || this.onView) {
					this.admin = initialType(v)
				}
			},
		},
	},
	data() {
		return {
			passwordConfirmation: '',
			error: {} as BackendError,
			attachments: undefined as Attachment | undefined,
			rules: getValidators('required', 'email', 'password', 'match', 'min'),
			admin: initialType(),
			showAttachmentError: false,
		}
	},
	computed: {
		...(mapGetters('auth', ['$currentUser']) as Accessors<{
			$currentUser: User
		}>),
	},
	methods: {
		onResetUserForm() {
			const formRef = this.$refs.formRef as VFormRef
			formRef.reset()
			this.$emit('closeModal')
			this.attachments = undefined
		},
		validate() {
			const formRef = this.$refs.formRef as VFormRef

			if (!this.attachments) {
				this.showAttachmentError = true
				formRef.validate()
			}
			//
			else if (formRef.validate()) {
				if (!this.attachments.file) {
					this.showAttachmentError = true
				} else {
					this.$emit(this.onUpdate ? 'updateAdmin' : 'createAdmin', {
						...this.admin,
						eStatus: this.admin.eStatus || 'Active',
						vProfileImage: this.attachments.file,
						password_confirmation: this.passwordConfirmation,
					})
				}
			}
		},
		onImageAttachment() {
			openFilePicker({ multiple: false }, (image: Attachment) => {
				this.attachments = image
				this.showAttachmentError = false
			})
		},
	},
})
